/*

// i18n support
import {
  searchFilterSearchButton
} from '~/lang/langFile'
import { useLang } from '~/context/LangContext'

const { lang } = useLang()

searchFilterFilterButton(lang)[0]

*/

import { CityData } from '~/generated/graphql'
import { formatNumberWithComma } from '~/lib/utils/number'

export type SupportedLanguages = 'en' | 'fr' | 'es'
export type LangContentFormat = string[]
type LanguageContent = Record<SupportedLanguages, LangContentFormat>

// SEARCH META
export const searchMetaTitleKeywordOnly = (
  lang: SupportedLanguages,
  { keyword }: { keyword: string }
) => {
  const content: LanguageContent = {
    en: [`${keyword} Jobs: Search for New Openings Near You | Joblist`],
    fr: [`Emplois de ${keyword}: Recherche de nouveaux postes près de chez vous | Joblist`],
    es: [`Empleos de ${keyword}: Busque Nuevas Vacantes Cerca de Usted | Joblist`]
  }
  return content[lang]
}

export const searchMetaTitleKeywordAndLocation = (
  lang: SupportedLanguages,
  { keyword, city, state }: { keyword: string; city: string; state: string },
  cityData?: CityData | null
) => {
  const content: LanguageContent = {
    en: [
      cityData?.country?.toLowerCase() == 'uk'
        ? `${keyword} Jobs in ${cityData.city}, ${cityData.state} | Joblist`
        : `${keyword} Jobs in ${city}, ${state} | Joblist`
    ],
    fr: [`Emplois de ${keyword} à ${city}, ${state} | Joblist`],
    es: [`Empleos de ${keyword} en ${city}, ${state} | Joblist`]
  }
  return content[lang]
}

export const searchMetaTitleLocationOnly = (
  lang: SupportedLanguages,
  { city, state }: { city: string; state: string },
  jobsCount?: number,
  cityData?: CityData | null
) => {
  const content: LanguageContent = {
    en: [
      cityData?.country?.toLowerCase() == 'uk'
        ? `Jobs in ${cityData.city}, ${cityData.state}: ${
            jobsCount ? ` ${formatNumberWithComma(jobsCount)} Openings Today` : ''
          } | Joblist`
        : `${city} Jobs: Search ${
            jobsCount ? ` ${formatNumberWithComma(jobsCount)}` : ''
          } Openings in ${city}, ${state} | Joblist`
    ],
    fr: [
      `Emplois à ${city}: Recherche ${
        jobsCount ? ` ${formatNumberWithComma(jobsCount)}` : ''
      } offres d'emplois à ${city}, ${state} | Joblist`
    ],
    es: [
      `Empleos en ${city}: Busque ${
        jobsCount ? ` ${formatNumberWithComma(jobsCount)}` : ''
      } Ofertas de Empleo en ${city}, ${state} | Joblist`
    ]
  }
  return content[lang]
}

export const searchMetaDescriptionKeywordOnly = (
  lang: SupportedLanguages,
  { keyword }: { keyword: string },
  jobsCount?: number
) => {
  const content: LanguageContent = {
    en: [
      `Find the best ${keyword} jobs at Joblist. Search across ${
        jobsCount ? ` ${formatNumberWithComma(jobsCount)}` : ''
      } opportunities and easily apply today!`
    ],
    fr: [
      `Trouvez les meilleurs emplois de ${keyword} avec Joblist. Recherchez parmi des ${
        jobsCount ? ` ${formatNumberWithComma(jobsCount)}` : ''
      } d'opportunités et postulez facilement dès aujourd'hui.`
    ],
    es: [
      `Encuentra los mejores trabajos de ${keyword} con Joblist. ¡Busque entre ${
        jobsCount ? ` ${formatNumberWithComma(jobsCount)}` : ''
      } oportunidades y aplique fácilmente hoy!`
    ]
  }
  return content[lang]
}

export const searchMetaDescriptionKeywordAndLocation = (
  lang: SupportedLanguages,
  { keyword, city, state }: { keyword: string; city: string; state: string },
  jobsCount?: number
) => {
  const content: LanguageContent = {
    en: [
      `Find the best ${keyword} jobs in ${city} at Joblist. Search across ${
        jobsCount ? ` ${formatNumberWithComma(jobsCount)}` : ''
      } opportunities and easily apply today!`
    ],
    fr: [
      `Trouvez les meilleurs emplois de ${keyword} à ${city} avec Joblist. Recherchez parmi des ${
        jobsCount ? ` ${formatNumberWithComma(jobsCount)}` : ''
      } d'opportunités et postulez facilement dès aujourd'hui.`
    ],
    es: [
      `Encuentra los mejores trabajos de ${keyword} en ${city} con Joblist. ¡Busque entre ${
        jobsCount ? ` ${formatNumberWithComma(jobsCount)}` : ''
      } oportunidades y aplique fácilmente hoy!`
    ]
  }
  return content[lang]
}

export const searchMetaDescriptionLocationOnly = (
  lang: SupportedLanguages,
  { city }: { city: string },
  jobsCount?: number
) => {
  const content: LanguageContent = {
    en: [
      `Find new job opportunities in ${city} at Joblist. Search ${
        jobsCount ? ` across ${formatNumberWithComma(jobsCount)}` : ''
      } listings and easily apply today!`
    ],
    fr: [
      `Trouvez de nouvelles opportunités d'emplois à ${city} avec Joblist. Recherche à travers ${
        jobsCount ? ` across ${formatNumberWithComma(jobsCount)}` : ''
      } et postulez facilement dès aujourd'hui!`
    ],
    es: [
      `Encuentra nuevas oportunidades de trabajo en ${city} con Joblist. ¡Busque entre ${
        jobsCount ? ` ${formatNumberWithComma(jobsCount)}` : ''
      } oportunidades y aplique fácilmente hoy!`
    ]
  }
  return content[lang]
}

// SEARCH NAV
export const searchNav = (lang: SupportedLanguages) => {
  const content: LanguageContent = {
    en: ['Find Jobs'],
    fr: ['Rechercher Emplois'],
    es: ['Busca Empleos']
  }
  return content[lang]
}

// SEARCH MODAL
export const searchButtton = (lang: SupportedLanguages) => {
  const content: LanguageContent = {
    en: ['Find Jobs'],
    fr: ['Rechercher'],
    es: ['Busca Empleos']
  }
  return content[lang]
}

export const searchKeywordPlaceholder = (lang: SupportedLanguages) => {
  const content: LanguageContent = {
    en: ['Job title or keyword'],
    fr: ['Titre ou mot-clé'],
    es: ['Título o palabra clave']
  }
  return content[lang]
}

export const searchLocationPlaceholder = (lang: SupportedLanguages) => {
  const content: LanguageContent = {
    en: ['City or zipcode'],
    fr: ['Ville ou code postal'],
    es: ['Ciudad o código postal']
  }
  return content[lang]
}

export const searchModalTitle = (lang: SupportedLanguages) => {
  const content: LanguageContent = {
    en: ['What are you looking for?'],
    fr: ['Que cherchez-vous?'],
    es: ['¿Qué está buscando?']
  }
  return content[lang]
}

export const companySearchModalTitle = (lang: SupportedLanguages) => {
  const content: LanguageContent = {
    en: ['What are you looking for?'],
    fr: ['Que cherchez-vous?'],
    es: ['¿Qué está buscando?']
  }
  return content[lang]
}

export const searchModalCompareText = (lang: SupportedLanguages) => {
  const content: LanguageContent = {
    en: [`Compare with`],
    fr: [`Comparer avec`],
    es: [`Comparar con`]
  }
  return content[lang]
}

export const searchModalCompareTextePage = (lang: SupportedLanguages) => {
  const content: LanguageContent = {
    en: [`Show similar jobs on`],
    fr: [`Ver trabajos similares en`],
    es: [`Voir des emplois similaires sur`]
  }
  return content[lang]
}

// SEARCH FILTERS
export const searchFilterTitleLabel = (lang: SupportedLanguages) => {
  const content: LanguageContent = {
    en: [`Title`],
    fr: [`Titre`],
    es: [`Título`]
  }
  return content[lang]
}

export const searchFilterCompanyLabel = (lang: SupportedLanguages) => {
  const content: LanguageContent = {
    en: [`Company`],
    fr: [`Enterprise`],
    es: [`Empresa`]
  }
  return content[lang]
}

export const searchFilterWithinLabel = (lang: SupportedLanguages) => {
  const content: LanguageContent = {
    en: [`Within`],
    fr: [`Dans un rayon de`],
    es: [`Dentro de`]
  }
  return content[lang]
}

export const searchFilterLocationLabel = (lang: SupportedLanguages) => {
  const content: LanguageContent = {
    en: [`Locations`],
    fr: [`Distance`],
    es: [`Distancia`]
  }
  return content[lang]
}

export const searchFilterMilesLabel = (lang: SupportedLanguages) => {
  const content: LanguageContent = {
    en: [`Miles`],
    fr: [`Miles`],
    es: [`Millas`]
  }
  return content[lang]
}

export const searchFilterAnyLabel = (lang: SupportedLanguages) => {
  const content: LanguageContent = {
    en: [`Any`],
    fr: [`Tout`],
    es: [`Cualquier`]
  }
  return content[lang]
}

export const searchFilterWorkSettingLabel = (lang: SupportedLanguages) => {
  const content: LanguageContent = {
    en: [`Work Setting`],
    fr: [`Environnement`],
    es: [`Tipo de Ambiente`]
  }
  return content[lang]
}

export const searchFilterRetailLabel = (lang: SupportedLanguages) => {
  const content: LanguageContent = {
    en: [`Retail`],
    fr: [`Au détail`],
    es: [`Al Detalle`]
  }
  return content[lang]
}

export const searchFilterFoodServicesLabel = (lang: SupportedLanguages) => {
  const content: LanguageContent = {
    en: [`Food Services`],
    fr: [`Restaurants`],
    es: [`Restaurantes`]
  }
  return content[lang]
}

export const searchFilterWarehouseLabel = (lang: SupportedLanguages) => {
  const content: LanguageContent = {
    en: [`Warehouse`],
    fr: [`Entrepôt`],
    es: [`Depósito`]
  }
  return content[lang]
}

export const searchFilterDriverLabel = (lang: SupportedLanguages) => {
  const content: LanguageContent = {
    en: [`Driver`],
    fr: [`Conducteur`],
    es: [`Conductor`]
  }
  return content[lang]
}

export const searchFilterHospitalityLabel = (lang: SupportedLanguages) => {
  const content: LanguageContent = {
    en: [`Hospitality`],
    fr: [`Hospitalité`],
    es: [`Hospitalidad`]
  }
  return content[lang]
}

export const searchFilterPostedLabel = (lang: SupportedLanguages) => {
  const content: LanguageContent = {
    en: [`Posted`],
    fr: [`Date de publication`],
    es: [`Fecha de Publicación`]
  }
  return content[lang]
}

export const searchFilterTodayLabel = (lang: SupportedLanguages) => {
  const content: LanguageContent = {
    en: [`Today`],
    fr: [`Dernières 24 heures`],
    es: [`Últimas 24 Horas`]
  }
  return content[lang]
}

export const searchFilterThisWeekLabel = (lang: SupportedLanguages) => {
  const content: LanguageContent = {
    en: [`This Week`],
    fr: [`7 derniers jours`],
    es: [`Últimos 7 Días`]
  }
  return content[lang]
}

export const searchFilterLastTwoWeeksLabel = (lang: SupportedLanguages) => {
  const content: LanguageContent = {
    en: [`Last Two Weeks`],
    fr: [`14 derniers jours`],
    es: [`Últimos 14 Días`]
  }
  return content[lang]
}

export const searchFilterLast30DaysLabel = (lang: SupportedLanguages) => {
  const content: LanguageContent = {
    en: [`Last 30 Days`],
    fr: [`30 derniers jours`],
    es: [`Últimos 30 Días`]
  }
  return content[lang]
}

export const searchFilterAnyDateLabel = (lang: SupportedLanguages) => {
  const content: LanguageContent = {
    en: [`Any Date`],
    fr: [`Toute date`],
    es: [`Cualquier Fecha`]
  }
  return content[lang]
}

export const searchFilterMoreFiltersLabel = (lang: SupportedLanguages) => {
  const content: LanguageContent = {
    en: [`More Filters`],
    fr: [`Plus de filtres`],
    es: [`Más Filtros`]
  }
  return content[lang]
}

export const searchFilterApplyButton = (lang: SupportedLanguages) => {
  const content: LanguageContent = {
    en: [`Apply`],
    fr: [`Appliquer`],
    es: [`Aplicar Filtros`]
  }
  return content[lang]
}

export const searchFilterShow = (lang: SupportedLanguages) => {
  const content: LanguageContent = {
    en: [`Show`],
    fr: [``],
    es: [``]
  }
  return content[lang]
}

export const searchFilterOnly = (lang: SupportedLanguages) => {
  const content: LanguageContent = {
    en: [`only`],
    fr: [`Seulement`],
    es: [`Sólo`]
  }
  return content[lang]
}

export const searchFilterJobs = (lang: SupportedLanguages) => {
  const content: LanguageContent = {
    en: [`jobs`],
    fr: [`emplois`],
    es: [`empleos`]
  }
  return content[lang]
}

export const searchFilterEntryLevelTitle = (lang: SupportedLanguages) => {
  const content: LanguageContent = {
    en: [`Entry-Level`],
    fr: [`Niveau d'entrée`],
    es: [`Nivel de Entrada`]
  }
  return content[lang]
}

export const searchFilterRemoteTitle = (lang: SupportedLanguages) => {
  const content: LanguageContent = {
    en: [`Remote`],
    fr: [`Remote`],
    es: [`Remoto`]
  }
  return content[lang]
}

export const searchFilterPartTimeTitle = (lang: SupportedLanguages) => {
  const content: LanguageContent = {
    en: [`Part-Time`],
    fr: [`Temps partiel`],
    es: [`Tiempo Parcial`]
  }
  return content[lang]
}

export const searchFilterHourlyTitle = (lang: SupportedLanguages) => {
  const content: LanguageContent = {
    en: [`Hourly`],
    fr: [`Horaire`],
    es: [`Por Hora`]
  }
  return content[lang]
}

export const searchFilterPhysicalLaborTitle = (lang: SupportedLanguages) => {
  const content: LanguageContent = {
    en: [`Physical Labor`],
    fr: [`Travail physique`],
    es: [`Trabajo Físico`]
  }
  return content[lang]
}

export const searchFilterEntryLevelDesc = (lang: SupportedLanguages) => {
  const content: LanguageContent = {
    en: [`entry-level jobs`],
    fr: [`niveau d'entrée emplois`],
    es: [`trabajos sin experiencia`]
  }
  return content[lang]
}

export const searchFilterRemoteDesc = (lang: SupportedLanguages) => {
  const content: LanguageContent = {
    en: [`remote jobs`],
    fr: [`remote emplois`],
    es: [`empleos remoto`]
  }
  return content[lang]
}

export const searchFilterPartTimeDesc = (lang: SupportedLanguages) => {
  const content: LanguageContent = {
    en: [`part-time jobs`],
    fr: [`temps partiel emplois`],
    es: [`empleos de tiempo parcial`]
  }
  return content[lang]
}

export const searchFilterHourlyDesc = (lang: SupportedLanguages) => {
  const content: LanguageContent = {
    en: [`hourly jobs`],
    fr: [`horaire emplois`],
    es: [`empleos por hora`]
  }
  return content[lang]
}

export const searchFilterPhysicalLaborDesc = (lang: SupportedLanguages) => {
  const content: LanguageContent = {
    en: [`physical labor jobs`],
    fr: [`travail physique emplois`],
    es: [`trabajo físico`]
  }
  return content[lang]
}

export const searchFilterEditSearchLabel = (lang: SupportedLanguages) => {
  const content: LanguageContent = {
    en: [`Edit search`],
    fr: [`Recherche d'édition`],
    es: [`Editar búsqueda`]
  }
  return content[lang]
}

export const searchFilterFilterButton = (lang: SupportedLanguages) => {
  const content: LanguageContent = {
    en: [`Filter`],
    fr: [`Filres`],
    es: [`Filtros`]
  }
  return content[lang]
}

export const searchFilterSearchButton = (lang: SupportedLanguages) => {
  const content: LanguageContent = {
    en: [`Search`],
    fr: [`Rechercher`],
    es: [`Buscar`]
  }
  return content[lang]
}

export const searchFilterLoadingLabel = (lang: SupportedLanguages) => {
  const content: LanguageContent = {
    en: [`Loading...`],
    fr: [`Recherche...`],
    es: [`Buscando...`]
  }
  return content[lang]
}

export const searchFilterNoTitlesLabel = (lang: SupportedLanguages) => {
  const content: LanguageContent = {
    en: [`No titles found`],
    fr: [`Aucun titre trouvé`],
    es: [`No se encontraron títulos`]
  }
  return content[lang]
}

export const searchFilterNoCompaniesLabel = (lang: SupportedLanguages) => {
  const content: LanguageContent = {
    en: [`No companies found`],
    fr: [`Aucune entreprise trouvée`],
    es: [`No se encontraron empresas`]
  }
  return content[lang]
}

export const searchFilterClearShortLabel = (lang: SupportedLanguages) => {
  const content: LanguageContent = {
    en: [`Clear`],
    fr: [`Réinitialiser`],
    es: [`Restablecer`]
  }
  return content[lang]
}

type Radius =
  | 'WITHIN_100_MILES'
  | 'WITHIN_50_MILES'
  | 'WITHIN_25_MILES'
  | 'WITHIN_10_MILES'
  | 'WITHIN_5_MILES'
  | 'WITHIN_100_KMS'
  | 'WITHIN_50_KMS'
  | 'WITHIN_25_KMS'
  | 'WITHIN_10_KMS'
  | 'WITHIN_5_KMS'
  | 'ANY_LOCATION'

type RadiusVals = { [key in Radius]: { en: string; fr: string; es: string } }

export const LocationRadiusToText = (lang: SupportedLanguages, radius: Radius) => {
  const radiusValues: RadiusVals = {
    WITHIN_100_MILES: {
      en: 'Within 100 Miles',
      fr: 'Dans un rayon de 100 miles',
      es: 'Dentro de 100 Millas'
    },
    WITHIN_50_MILES: {
      en: 'Within 50 Miles',
      fr: 'Dans un rayon de 50 miles',
      es: 'Dentro de 50 Millas'
    },
    WITHIN_25_MILES: {
      en: 'Within 25 Miles',
      fr: 'Dans un rayon de 25 miles',
      es: 'Dentro de 25 Millas'
    },
    WITHIN_10_MILES: {
      en: 'Within 10 Miles',
      fr: 'Dans un rayon de 10 miles',
      es: 'Dentro de 10 Millas'
    },
    WITHIN_5_MILES: {
      en: 'Within 5 Miles',
      fr: 'Dans un rayon de 5 miles',
      es: 'Dentro de 5 Millas'
    },
    WITHIN_100_KMS: { en: 'Within 100 Km', fr: 'Dans un rayon de km', es: 'Dentro de 100 Km' },
    WITHIN_50_KMS: { en: 'Within 50 Km', fr: 'Dans un rayon de km', es: 'Dentro de 50 Km' },
    WITHIN_25_KMS: { en: 'Within 25 Km', fr: 'Dans un rayon de km', es: 'Dentro de 25 Km' },
    WITHIN_10_KMS: { en: 'Within 10 Km', fr: 'Dans un rayon de km', es: 'Dentro de 10 Km' },
    WITHIN_5_KMS: { en: 'Within 5 Km', fr: 'Dans un rayon de km', es: 'Dentro de 5 Km' },
    ANY_LOCATION: { en: 'Any Distance', fr: 'Toute distance', es: 'Cualquier Distancia' }
  }

  const content: LanguageContent = {
    en: [radiusValues[radius]['en']],
    fr: [radiusValues[radius]['fr']],
    es: [radiusValues[radius]['es']]
  }
  return content[lang]
}

type DateRange = 'TODAY' | 'THIS_WEEK' | 'LAST_2_WEEKS' | 'LAST_30_DAYS' | 'ANY_DATE'
type DateVals = { [key in DateRange]: { en: string; fr: string; es: string } }

export const postedDatesToText = (lang: SupportedLanguages, dateRange: DateRange) => {
  const dateValues: DateVals = {
    TODAY: { en: 'Today', fr: 'Dernières 24 heures', es: 'Últimas 24 Horas' },
    THIS_WEEK: { en: 'This Week', fr: '7 derniers jours', es: 'Últimos 7 Días' },
    LAST_2_WEEKS: { en: 'Last 2 Weeks', fr: '14 derniers jours', es: 'Últimos 14 Días' },
    LAST_30_DAYS: { en: 'Last 30 Days', fr: '30 derniers jours', es: 'Últimos 30 Días' },
    ANY_DATE: { en: 'Any Date', fr: 'Toute date', es: 'Cualquier Fecha' }
  }

  const content: LanguageContent = {
    en: [dateValues[dateRange]['en']],
    fr: [dateValues[dateRange]['fr']],
    es: [dateValues[dateRange]['es']]
  }
  return content[lang]
}

// SEARCH SIDEBAR
export const sideBarHeader = (lang: SupportedLanguages) => {
  const content: LanguageContent = {
    en: ['Jobs'],
    fr: ['Emplois'],
    es: ['Empleo']
  }
  return content[lang]
}

export const sideBarHeaderWithJobTitle = (
  lang: SupportedLanguages,
  { jobTitle }: { jobTitle: string }
) => {
  const content: LanguageContent = {
    en: [`${jobTitle} Job Openings`],
    fr: [`Emplois de ${jobTitle}`],
    es: [`Empleos de ${jobTitle}`]
  }
  return content[lang]
}

export const sideBarHeaderWithLocation = (
  lang: SupportedLanguages,
  { location }: { location: string }
) => {
  const content: LanguageContent = {
    en: [`Job Openings in ${location}`],
    fr: [`Emplois à ${location}`],
    es: [`Empleos en ${location}`]
  }
  return content[lang]
}

export const sideBarHeaderWithJobCompany = (
  lang: SupportedLanguages,
  { jobCompany }: { jobCompany: string }
) => {
  const content: LanguageContent = {
    en: [`${jobCompany} Jobs`],
    fr: [`Emplois avec ${jobCompany}`],
    es: [`Empleos en ${jobCompany}`]
  }
  return content[lang]
}

export const sidebarParagraph = (lang: SupportedLanguages) => {
  const content: LanguageContent = {
    en: [
      `Search thousands of job openings. Get personalized results to find the right job for you.`,
      `We have millions of job postings. All job listings are refreshed hourly, so you can always check back to find the latest jobs that are hiring now.`
    ],
    fr: [
      `Recherchez des milliers d'offres d'emploi. Obtenez des résultats personnalisés pour trouver l'emploi qui vous convient.`,
      `Nous avons des millions d'offres d'emploi. Toutes les offres d'emploi sont actualisées toutes les heures,
      vous pouvez donc toujours revenir pour trouver les dernières offres d'emploi qui embauchent maintenant.`
    ],
    es: [
      `Busque millas de ofertas de empleo. Obtenga resultados personalizados y encuentre el trabajo adecuado para usted.`,
      `Tenemos millones de ofertas de empleo. Los listados de empleo se actualizan cada hora para que puedas encontrar los últimos puestos.`
    ]
  }
  return content[lang]
}

export const sidebarParagraphWithLocation = (
  lang: SupportedLanguages,
  { location }: { location: string }
) => {
  const content: LanguageContent = {
    en: [
      `Search thousands of job openings in ${location}. Get personalized results to find the right job for you.`,
      `We have millions of job postings. All job listings are refreshed hourly, so you can always check back to find the latest jobs near ${location} that are hiring now.`
    ],
    fr: [
      `Recherchez des milliers d'offres d'emploi à ${location}. Obtenez des résultats personnalisés pour trouver l'emploi qui vous convient.`,
      `Nous avons des millions d'offres d'emploi. Toutes les offres d'emploi sont actualisées toutes les heures,
      vous pouvez donc toujours revenir pour trouver les dernières offres d'emploi près de ${location} qui embauchent maintenant.`
    ],
    es: [
      `Busque millas de ofertas de empleo en ${location}. Obtenga resultados personalizados y encuentre el trabajo adecuado para usted.`,
      `Tenemos millones de ofertas de empleo. Los listados de empleo se actualizan cada hora para que puedas encontrar los últimos puestos cerca de ${location}.`
    ]
  }
  return content[lang]
}

export const sideBarParagraphWithJobTitle = (
  lang: SupportedLanguages,
  { jobTitle }: { jobTitle: string }
) => {
  const content: LanguageContent = {
    en: [
      `Search thousands of ${jobTitle} job openings. Get personalized results to find the right job for you.`,
      `We have millions of job postings. All job listings are refreshed hourly, so you can always check back to find the latest ${jobTitle} jobs that are hiring now.`
    ],
    fr: [
      `Recherchez des milliers d'offres d'emploi de ${jobTitle}. Obtenez des résultats personnalisés pour trouver l'emploi qui vous convient.`,
      `Nous avons des millions d'offres d'emploi. Toutes les offres d'emploi sont actualisées toutes les heures,
      vous pouvez donc toujours revenir pour trouver les dernières offres d'emploi de ${jobTitle} qui embauchent maintenant.`
    ],
    es: [
      `Busque millas de ofertas de empleo de ${jobTitle}. Obtenga resultados personalizados y encuentre el trabajo adecuado para usted.`,
      `Tenemos millones de ofertas de empleo. Los listados de empleo se actualizan cada hora para que puedas encontrar los últimos puestos como ${jobTitle}.`
    ]
  }
  return content[lang]
}

export const sideBarParagraphWithJobCompany = (
  lang: SupportedLanguages,
  { jobCompany }: { jobCompany: string }
) => {
  const content: LanguageContent = {
    en: [
      `Search thousands of ${jobCompany} job openings. Get personalized results to find the right job for you.`,
      `We have millions of job postings. All job listings are refreshed hourly, so you can always check back to find the latest ${jobCompany} jobs that are hiring now.`
    ],
    fr: [
      `Recherchez des milliers d'offres d'emploi avec ${jobCompany}. Obtenez des résultats personnalisés pour trouver l'emploi qui vous convient.`,
      `Nous avons des millions d'offres d'emploi. Toutes les offres d'emploi sont actualisées toutes les heures,
      vous pouvez donc toujours revenir pour trouver les dernières offres d'emploi avec ${jobCompany} qui embauchent maintenant.`
    ],
    es: [
      `Busque millas de ofertas de empleo en ${jobCompany}. Obtenga resultados personalizados y encuentre el trabajo adecuado para usted.`,
      `Tenemos millones de ofertas de empleo. Los listados de empleo se actualizan cada hora para que puedas encontrar los últimos puestos en ${jobCompany}.`
    ]
  }
  return content[lang]
}

// SEARCH MAIN
export const SaveCTAHeadline = (lang: SupportedLanguages) => {
  const content: LanguageContent = {
    en: [
      `Save jobs you're interested in to access them later - it's job searching made easier!
    `
    ],
    fr: [
      `Sauvegardez les emplois qui vous intéressent pour y accéder plus tard - c'est la recherche d'emploi simplifiée!`
    ],
    es: [
      `Guarde los trabajos que le interesan para acceder a ellos más tarde: ¡la búsqueda de trabajo es más fácil!`
    ]
  }
  return content[lang]
}

export const SaveCTAButton = (lang: SupportedLanguages) => {
  const content: LanguageContent = {
    en: [`Sign Up To Save`],
    fr: [`Enregistrez-vous pour sauvegarder`],
    es: [`Regístrese Para Guardar`]
  }
  return content[lang]
}

export const ExpandedCheckSpelling = (lang: SupportedLanguages) => {
  const content: LanguageContent = {
    en: [`Check your spelling`],
    fr: [`Vérifiez votre orthographe`],
    es: [`Revise su ortografía`]
  }
  return content[lang]
}

export const ExpandedCheckFilters = (lang: SupportedLanguages) => {
  const content: LanguageContent = {
    en: [`Make your filters less restrictive`],
    fr: [`Rendez vos filtres moins restrictifs`],
    es: [`Reducir el número de filtros`]
  }
  return content[lang]
}

export const ExpandedClearFilters = (lang: SupportedLanguages) => {
  const content: LanguageContent = {
    en: [`Clear Filters`],
    fr: [`Réinitialiser les filtres`],
    es: [`Eliminar los Filtros`]
  }
  return content[lang]
}

export const ExpandedCheckKeywords = (lang: SupportedLanguages) => {
  const content: LanguageContent = {
    en: [`Try broader, more general keywords`],
    fr: [`Essayez des mots-clés plus larges et plus généraux`],
    es: [`Pruebe con palabras clave más amplias y generales`]
  }
  return content[lang]
}

// NAVIGATION
export const HomeLabel = (lang: SupportedLanguages) => {
  const content: LanguageContent = {
    en: [`Home`],
    fr: [`Accueil`],
    es: [`Inicio`]
  }
  return content[lang]
}

export const LogInLabel = (lang: SupportedLanguages) => {
  const content: LanguageContent = {
    en: [`Log In`],
    fr: [`Connexion`],
    es: [`Ingresar`]
  }
  return content[lang]
}

export const ProfileLabel = (lang: SupportedLanguages) => {
  const content: LanguageContent = {
    en: [`Profile`],
    fr: [`Compte`],
    es: [`Cuenta`]
  }
  return content[lang]
}

export const ContactLabel = (lang: SupportedLanguages) => {
  const content: LanguageContent = {
    en: [`Contact`],
    fr: [`Contacter`],
    es: [`Contactar`]
  }
  return content[lang]
}

export const relatedJobsByCityBlockHeading = (lang: SupportedLanguages) => {
  const content: LanguageContent = {
    en: [`Search similar jobs in`],
    fr: [`Recherchez des emplois similaires dans`],
    es: [`Busca empleos similares en`]
  }
  return content[lang]
}

export const NearbyCitiesBlockHeadingNear = (lang: SupportedLanguages) => {
  const content: LanguageContent = {
    en: [`Cities near`],
    fr: [`Villes à proximité`],
    es: [`Ciudades cerca de`]
  }
  return content[lang]
}

export const NearbyCitiesBlockHeadingHiring = (lang: SupportedLanguages) => {
  const content: LanguageContent = {
    en: [`hiring`],
    fr: [`embauche`],
    es: [`contratando`]
  }
  return content[lang]
}

export const NearbyCitiesBlockHeadingRoles = (lang: SupportedLanguages) => {
  const content: LanguageContent = {
    en: [`roles`],
    fr: [``],
    es: [``]
  }
  return content[lang]
}
